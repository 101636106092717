import React, { useState, useEffect } from "react";
import {
    Button, ButtonGroup, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
    Grid, Link, Snackbar, Stack, TextField, Typography
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useParams} from "react-router";
import {AAR, AppUserProfile, Conduct} from "../../../interfaces/interfaces";
import AARComponent from "../../../components/scenario/AARComponent";
import QuickLoaderComponent from "../../../components/cards/QuickLoaderComponent";
import MainCard from "../../../components/MainCard";
import {Link as RouterLink} from "react-router-dom";
import {ArrowBackOutlined, PublishOutlined, Send, SlideshowOutlined} from "@mui/icons-material";
import PrintPDF from "../../../components/PrintAARPDF";
import AAROverviewDialog from "../../../components/dialog/AAROverviewDialog";
import ConductTranscriptComponent from "../../../components/tabletop/ConductTranscriptComponent";
import ActionAnalysisComponent from "../../../ActionAnalysisComponent";

interface Params {
    companyId: string;
    scenarioId: string;
    conductId: string;
    portalId: string;
}
const PortalAfterActionReportPage = () => {
    // @ts-ignore
    const { companyId,scenarioId,conductId, portalId } = useParams<Params>();
    const [aar, setAAR] = useState<AAR>();
    const [isAARLoading, setIsAARLoading] = useState<boolean>(false);
    const token = localStorage.getItem('token');
    const [conduct , setConduct] = useState<Conduct>();

    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false);
    const [openRegenerateDialog, setOpenRegenerateDialog] = useState(false); // New state for regenerate dialog
    const [userProfile, setUserProfile] = useState<AppUserProfile>();
    const [checked, setChecked] = useState(false);
    const [openSendtoDialog, setOpenSendtoDialog] = useState(false);


    const handleRegenerateDialog = () => {
        setOpenRegenerateDialog(true);
    };

    const closeRegenerateDialog = () => {
        setOpenRegenerateDialog(false);
    };

    const handleChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setChecked(event.target.checked);
    };
    async function handlePublishAAR() {
        try {
            setIsAARLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/aar/publishAAR/' +
                token + '/' + companyId + '/' + portalId+'/'+scenarioId + '/' + conductId);
            const data = await response.json();
            setAAR(data);
            setIsAARLoading(false);
        } catch (error) {
            console.error("Error publishing aar:", error);
        }
        closePublishDialog();
    }
    const handlePublishDialog = () => {
        setOpenPublishDialog(true);
    };

    const closePublishDialog = () => {
        setOpenPublishDialog(false);
    };


    const fetchAAR = async () => {
        try {
            setIsAARLoading(true);

            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/tabletop/aar/' + token + '/' + companyId + '/' + portalId+'/'+scenarioId + '/' + conductId);
            const data = await response.json();
            setAAR(data);
            setIsAARLoading(false);

        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    };

    const handleAARUpdate = async () => {
        fetchAAR();
    }

    useEffect(() => {
        const fetchData = async () => {
            fetchAAR();
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/gettabletop/' + token + '/' + companyId + '/' +
                    portalId+'/'+scenarioId + '/' + conductId);
                const data = await response.json();
                setConduct(data);
            } catch (error) {
                console.error("Error updating scenario:", error);
            }
            //set user profile
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/auth/profile/view/' + token);
                const data = await response.json();
                setUserProfile(data);
            } catch (error) {
                console.error("Error updating scenario:", error);
            }
        };
        fetchData();
    }, []);


    const handleRegenerateAAR = async () => {
        setIsAARLoading(true);
        fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/scenario/tabletop/aar/regen/' + token + '/' + companyId + '/' + portalId + '/' + scenarioId + '/' + conductId)
            .then(response => response.json())
            .then(data => {
                setAAR(data);
                setIsAARLoading(false);
            })
            .catch(error => {
                console.error("Error regenerating aar:", error);
            });
        closeRegenerateDialog();
    };


    function handleUnpublishAAR() {
        setIsAARLoading(true);
        fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/aar/unpublishaar/' + token + '/' + companyId + '/' + portalId+'/'+scenarioId + '/' + conductId)
            .then(response => response.json())
            .then(data => {
                setAAR(data);
                setIsAARLoading(false);
            })
            .catch(error => {
                console.error("Error unpublishing aar:", error);
            });

        function closeUnpublishDialog() {
            setOpenUnpublishDialog(false);
        }

        closeUnpublishDialog();
    }

    const [aarOverviewOpen, setAAROverviewOpen] = useState(false);

    const handleAAROverviewOpen = () => {
        setAAROverviewOpen(true);
    };

    const handleAAROverviewClose = () => {
        setAAROverviewOpen(false);
    };

    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    function handleSendToDialog() {
        setOpenSendtoDialog(true);
    }

    const [vantaSentAlert, setVantaSentAlert] = useState(false);

    async function handleVantaSend() {
        // Step 1: Open a new window for the OAuth handshake
        const oauthWindow = window.open(
            `${process.env.REACT_APP_REST_API_URL}/oauth/vanta/opsbook?sendToken=true`,
            '_blank',
            'width=600,height=600'
        );

        // Define the event listener function
        const messageListener = (event: MessageEvent) => {
           // console.log('Received message from Vanta:', event);
            // Validate the origin of the message
            if (event.origin !== process.env.REACT_APP_REST_API_URL) {
                console.warn('Origin mismatch, ignoring message');
                return;
            }
            setVantaSentAlert(true);
            try {
                // Parse the token data from the event
                const data = JSON.parse(event.data);
                const accessToken = data.access_token;

                if (accessToken) {
                    // Step 4: Close the OAuth window
                    setOpenSendtoDialog(false);
                    oauthWindow?.close();
                    // Step 3: Use the token to make the API call
                    fetch(
                        `${process.env.REACT_APP_REST_API_URL}/api/portal/conduct/aar/sendtovanta/${token}/${companyId}/${portalId}/${scenarioId}/${conductId}/${accessToken}`
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            setIsAARLoading(false);
                            //console.log('Successfully sent to Vanta:', data);
                        })
                        .catch((error) => {
                            console.error('Error sending AAR to Vanta:', error);
                        });
                }
            } catch (error) {
                console.error('Error parsing token JSON:', error);
            } finally {
                // Remove the event listener
                window.removeEventListener('message', messageListener);
            }
        };

        // Step 2: Listen for a message event from the OAuth window
        window.addEventListener('message', messageListener, false);
    }

    return (
      <Grid container rowSpacing={4.5} columnSpacing={3}>
          <Grid item xs={12}>
              <MainCard title={aar?.scenarioName + ': After-Action Report'} secondary={
                  <Grid item>
                      <Link color="primary" component={RouterLink} to={`/portal/viewportal/${companyId}/${portalId}`}>
                          <Button variant="outlined" size={"small"}>
                              <ArrowBackOutlined/>&nbsp;Portal
                          </Button>
                      </Link>
                  </Grid>
              }

              >
                  <Grid item xs={12}>
                      {isAARLoading &&
                          <QuickLoaderComponent loadBanner={"Loading After-Action Report....."} />
                      }
                      {vantaSentAlert &&
                          <Snackbar
                          open={vantaSentAlert}
                          color={"success"}
                          onClose={() => setVantaSentAlert(false)}
                          autoHideDuration={5000}
                          message="Sending AAR to Vanta....."
                      />
                      }
                    </Grid>
                  <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item>
                          <Tabs value={selectedTab} onChange={handleTabChange}>
                              <Tab label="Report" />
                              <Tab label="Transcript" />
                              {userProfile && userProfile.role != 'PortalViewParticipant' &&
                                 <Tab label="Analysis" />
                              }
                          </Tabs>
                      </Grid>

                  </Grid>
                  <Grid item xs={12}>
              {selectedTab === 0 && aar &&
                  <Grid item={true} xs={12}>
                      <MainCard title="After-Action Report" secondary={
                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                          <ButtonGroup>
                              {aar && !aar.isPublished &&
                                  <>
                                      <Button
                                          variant="outlined"
                                          size={"small"}
                                          startIcon={<PublishOutlined/>}
                                          onClick={handlePublishDialog}
                                      >
                                          Publish
                                      </Button>
                                      <Button
                                          variant="outlined"
                                          size={"small"}
                                          startIcon={<PublishOutlined/>}
                                          onClick={handleRegenerateDialog}
                                      >
                                          Regenerate
                                      </Button>
                                  </>
                              }
                              {userProfile && userProfile.role != 'PortalViewParticipant' && aar && aar.isPublished &&
                                  <Button
                                      variant="outlined"
                                      size={"small"}
                                      startIcon={<PublishOutlined/>}
                                      onClick={() => setOpenUnpublishDialog(true)}
                                  >
                                      Unpublish
                                  </Button>
                              }
                              {aar && conduct &&
                                  <PrintPDF aar={aar} conduct={conduct} />
                              }
                              {aar && conduct &&
                                  <>
                                      <AAROverviewDialog title={aar.reportTitle} aar={aar} aarOverviewOpen={aarOverviewOpen} handleAAROverviewClose={handleAAROverviewClose}/>
                                      <Button
                                          variant="outlined"
                                          size={"small"}
                                          onClick={handleAAROverviewOpen}
                                          startIcon={<SlideshowOutlined/>}
                                      >Show</Button>
                                  </>
                              }
                              {aar && aar.isPublished &&
                                  <Button
                                      variant="outlined"
                                      size={"small"}
                                      startIcon={<Send/>}
                                      onClick={handleSendToDialog}
                                  >
                                      Send to...
                                  </Button>
                              }
                          </ButtonGroup>

                      </Stack>
                      }>
                          {conduct && <AARComponent conductId={conductId} companyId={companyId} conduct={conduct}
                                    scenarioId={scenarioId} aar={aar} editable={!aar.isPublished}
                                    onUpdate={handleAARUpdate} portalId={portalId} isPortal={true}/>
                          }
                      </MainCard>
                  </Grid>
              }

              {selectedTab === 1 && conduct &&
                  <ConductTranscriptComponent conduct={conduct}/>
              }
              {selectedTab === 2 && conduct && aar && companyId && portalId && scenarioId && userProfile && userProfile.role != 'PortalViewParticipant' &&
                  <ActionAnalysisComponent conduct={conduct} aar={aar} companyId={companyId}
                                           portalId={portalId} editable={true}
                                           scenarioId={scenarioId}
                                           onUpdate={handleAARUpdate}
                                           isPortal={true}
                  />
              }
                  </Grid>

          </MainCard>
          </Grid>


          <Dialog open={openPublishDialog} fullWidth={true} onClose={closePublishDialog}>
              <DialogTitle>Publish AAR to Portal</DialogTitle>
              <DialogContent>
                  <Typography variant="body1">
                      You are publishing the AAR for this tabletop to the portal. The AAR will be visible once published and not editable.
                  </Typography>
                  <Typography variant="body1">
                      <Checkbox checked={checked} onChange={handleChange} />
                      You acknowledge that you have reviewed the AAR and are ready to publish it.
                  </Typography>
              </DialogContent>
              <DialogActions>
                  <Button variant="contained" onClick={handlePublishAAR} color="primary" disabled={!checked}>Publish</Button>
                  <Button variant="contained" onClick={closePublishDialog}>Cancel</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openUnpublishDialog} fullWidth={true} onClose={() => setOpenUnpublishDialog(false)}>
              <DialogTitle>Unpublish AAR </DialogTitle>
              <DialogContent>
                  <Typography variant="body1">
                      You are unpublishing the AAR for this tabletop to the portal. The AAR will NOT BE visible and will become editable.
                  </Typography>

              </DialogContent>
              <DialogActions>
                  <Button variant="contained" onClick={handleUnpublishAAR} color="primary" >Unpublish</Button>
                  <Button variant="contained" onClick={() => setOpenUnpublishDialog(false)}>Cancel</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openRegenerateDialog} fullWidth={true} onClose={closeRegenerateDialog}>
              <DialogTitle>Regenerate AAR</DialogTitle>
              <DialogContent>
                  <Typography variant="body1">
                      You are about to regenerate the AAR for this tabletop. This action will overwrite the current AAR.
                  </Typography>
              </DialogContent>
              <DialogActions>
                  <Button variant="contained" onClick={handleRegenerateAAR} color="primary">Regenerate</Button>
                  <Button variant="contained" onClick={closeRegenerateDialog}>Cancel</Button>
              </DialogActions>
          </Dialog>

          <Dialog open={openSendtoDialog} fullWidth={true} onClose={() => setOpenSendtoDialog(false)}>
              <DialogTitle>Send AAR</DialogTitle>
              <DialogContent>
                  <Grid container={true} spacing={2}>
                      <Grid item={true} xs={4}>
                          <Typography variant="body1">
                              Email this AAR to:
                          </Typography>
                      </Grid>
                        <Grid item={true} xs={8}>
                            <TextField variant="outlined" fullWidth={true} label="Email Address" />
                        </Grid>
                      <Grid item={true} xs={12}><Divider/></Grid>
                      <Grid item={true} xs={4}>
                          <Typography variant="body1">
                              Or send this AAR to an integrated platform:
                          </Typography>
                      </Grid>
                      <Grid item={true} xs={8}>
                          <Button variant="contained"
                                  onClick={handleVantaSend}
                                  sx={{ backgroundColor: 'mediumpurple', '&:hover': { backgroundColor: 'darkviolet' } }}
                          >Vanta</Button>
                      </Grid>
                  </Grid>
              </DialogContent>
              <DialogActions>
                  <Button variant="contained" onClick={() => setOpenSendtoDialog(false)}>Cancel</Button>
              </DialogActions>
          </Dialog>
    </Grid>
  );
};

export default PortalAfterActionReportPage;