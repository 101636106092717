import React, { useState, useEffect } from "react";
import {
    Typography,
    Grid,
    Button, Stack, Link, ButtonGroup, Dialog, DialogTitle, DialogContent, FormControl, DialogActions
} from "@mui/material";
import {useParams} from "react-router";
import {AAR, Conduct} from "../../interfaces/interfaces";
import AARComponent from "../../components/scenario/AARComponent";
import QuickLoaderComponent from "../../components/cards/QuickLoaderComponent";
import MainCard from "../../components/MainCard";
import {Link as RouterLink} from "react-router-dom";
import {ArrowBack, PublishOutlined, Send} from "@mui/icons-material";
import PortalListSelect from "../../components/portal/PortalListSelect";
import PrintPDF from "../../components/PrintAARPDF";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ConductTranscriptComponent from "../../components/tabletop/ConductTranscriptComponent";
import AIAssistantIcon from "../../components/icon/AIAssistantIcon";
import ActionAnalysisComponent from "../../ActionAnalysisComponent";
import RecommendationAnalysisComponent from "../../RecommendationAnalysisComponent";
// import {ShareAltOutlined} from "@ant-design/icons";

interface Params {
    companyId: string;
    scenarioId: string;
    conductId: string;
}
const AfterActionReport = () => {
    // @ts-ignore
    const { companyId,scenarioId,conductId } = useParams<Params>();
    const [aar, setAAR] = useState<AAR>();
    const [isAARLoading, setIsAARLoading] = useState<boolean>(false);
    const token = localStorage.getItem('token');
    const [conduct , setConduct] = useState<Conduct>();
    const [showGenAAR, setShowGenAAR] = useState<boolean>(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [selectedPortalId, setSelectedPortalId] = useState<string>("");
    const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false);
    const [openRegenerateDialog, setOpenRegenerateDialog] = useState(false); // New state for regenerate dialog
    const [openSendtoDialog, setOpenSendtoDialog] = useState(false);

    const handleRegenerateDialog = () => {
        setOpenRegenerateDialog(true);
    };

    const closeRegenerateDialog = () => {
        setOpenRegenerateDialog(false);
    };

    const handleRegenerateAAR = async () => {
        setIsAARLoading(true);
        fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/conduct/aar/regen/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId)
            .then(response => response.json())
            .then(data => {
                setAAR(data);
                setIsAARLoading(false);
            })
            .catch(error => {
                console.error("Error regenerating aar:", error);
            });
        closeRegenerateDialog();
    };

    const handleGenAAR = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/tabletop/genAAR/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId);
            const data = await response.json();
            setAAR(data);
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    };

    const handleAARUpdate = async () => {
        try {
            setIsAARLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/tabletop/aar/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId);
            const data = await response.json();
            setAAR(data);
            setIsAARLoading(false);
            if (!aar) {
                setShowGenAAR(true);
            }
        } catch (error) {
            console.error("Error updating scenario:", error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsAARLoading(true);
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/tabletop/aar/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId);
                const data = await response.json();
                setAAR(data);
                setIsAARLoading(false);
                if (!aar) {
                    setShowGenAAR(true);
                }
            } catch (error) {
                console.error("Error updating scenario:", error);
            }
            try {
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/gettabletop/' + token + '/' + companyId + '/' + scenarioId + '/' + conductId);
                const data = await response.json();
                setConduct(data);
            } catch (error) {
                console.error("Error updating scenario:", error);
            }
        };
        fetchData();
    }, []);

    const handlePublishDialog = () => {
        setOpenPublishDialog(true);
    };

    const closePublishDialog = () => {
        setOpenPublishDialog(false);
    };

    function handlePortalSelect(portalId:string) {
        setSelectedPortalId(portalId);
    }
    async function handlePublishAAR() {
        //console.log("Publish AAR");
        try {
            setIsAARLoading(true);
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/tabletop/scenario/tabletop/publishAAR/' +
                token + '/' + companyId + '/' + scenarioId + '/' + conductId + '/' + selectedPortalId);
            const data = await response.json();
            setAAR(data);
            setIsAARLoading(false);
        } catch (error) {
            console.error("Error publishing aar:", error);
        }
        closePublishDialog();
    }
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setSelectedTab(newValue);
    };

    function handleUnpublishAAR() {
        setIsAARLoading(true);
        fetch(process.env.REACT_APP_REST_API_URL + '/api/portal/conduct/aar/unpublishaar/' + token + '/' + companyId + '/' + aar?.portalIds[0]+'/'+scenarioId + '/' + conductId)
            .then(response => response.json())
            .then(data => {
                setAAR(data);
                setIsAARLoading(false);
            })
            .catch(error => {
                console.error("Error unpublishing aar:", error);
            });

        function closeUnpublishDialog() {
            setOpenUnpublishDialog(false);
        }

        closeUnpublishDialog();
    }

    const [recommendationAnalysis, setRecommendationAnalysis] = useState<string>();
    function handleRecommendationAnalysisPersist(analysis: string) {
        setRecommendationAnalysis(analysis);
    }

    function handleSendToDialog() {
        setOpenSendtoDialog(true);
    }

    return (
      <Grid container rowSpacing={4.5} columnSpacing={3}>
                {!aar && showGenAAR && <Typography variant="h6">No AAR Generated Yet - <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleGenAAR}
                >
                   <AIAssistantIcon/> Generate
                </Button></Typography>}
            <Grid item xs={12}>
                <MainCard title={aar?.scenarioName + ': After-Action Report'} secondary={
                    <Grid item>
                        <ButtonGroup>
                            <Link color="primary" component={RouterLink} to={`/company/scenario/view/${companyId}/${scenarioId}`}>
                                <Button variant="outlined" size={"small"} startIcon={<ArrowBack />} >
                                    Scenario
                                </Button>
                            </Link>
                            <Link color="primary" component={RouterLink} to={`/company/viewcompany/${companyId}`}>
                                <Button variant="outlined" size={"small"} startIcon={<ArrowBack />} >
                                    Company
                                </Button>
                            </Link>
                        </ButtonGroup>
                    </Grid>
                }>
                <Grid item xs={12}>
            <MainCard sx={{ height: 1, '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column' } }}>

              <Grid item xs={12}>
              {isAARLoading &&
                  <QuickLoaderComponent loadBanner={"Loading After-Action Report....."} />
              }

                  <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item xs={6}>
                          <Tabs value={selectedTab} onChange={handleTabChange}>
                              <Tab label="Report" />
                              <Tab label="Transcript" />
                              <Tab label="Analysis" />
                              <Tab label="Recommendations" />
                          </Tabs>
                      </Grid>
                      <Grid item xs={6}>
                          <Stack direction="row" justifyContent="flex-end" spacing={1}>
                              <ButtonGroup>
                                  {/*<Button variant="outlined" size={"small"} startIcon={<ShareAltOutlined />} >Share</Button>*/}
                                  {aar && !aar.isPublished &&
                                   <>
                                       <Button
                                          variant="outlined"
                                          size={"small"}
                                          startIcon={<PublishOutlined/>}
                                          onClick={handlePublishDialog}
                                      >
                                          Publish
                                      </Button>

                                      <Button
                                              variant="outlined"
                                              size={"small"}
                                          startIcon={<PublishOutlined/>}
                                          onClick={handleRegenerateDialog}
                                      >
                                          Regenerate
                                      </Button>
                                   </>
                                  }

                                  { aar && aar.isPublished &&
                                      <Button
                                          variant="outlined"
                                          size={"small"}
                                          startIcon={<PublishOutlined/>}
                                          onClick={() => setOpenUnpublishDialog(true)}
                                      >
                                          Unpublish
                                      </Button>
                                  }
                                  {aar && aar.isPublished &&
                                      <Button
                                          variant="outlined"
                                          size={"small"}
                                          startIcon={<Send/>}
                                          onClick={handleSendToDialog}
                                      >
                                          Send to...
                                      </Button>
                                  }
                              </ButtonGroup>
                              <ButtonGroup>
                                  {aar && conduct &&
                                      <PrintPDF aar={aar} conduct={conduct} />
                                  }
                              </ButtonGroup>

                          </Stack>
                      </Grid>
                  </Grid>

              </Grid>
                {selectedTab === 0 && aar &&
                  <Grid item xs={12}>
                  <Grid container={true} spacing={2} >
                        <Grid item xs={12}>
                            {aar && conduct &&
                                <AARComponent conductId={conductId} companyId={companyId} conduct={conduct}
                                              scenarioId={scenarioId} aar={aar} editable={!aar.isPublished}/>
                            }
                        </Grid>
                      </Grid>
                  </Grid>
                }
                {selectedTab === 1 && conduct &&
                    <Grid item xs={12}>
                        <Grid container={true} spacing={2} >
                            <Grid item xs={12}>
                                <ConductTranscriptComponent conduct={conduct}/>
                            </Grid>
                        </Grid>
                    </Grid>

                }
                {selectedTab === 2 && conduct && aar && companyId && scenarioId &&conduct.portalId &&
                    <Grid item xs={12}>
                        <Grid container={true} spacing={2} >
                            <Grid item xs={12}>
                                <ActionAnalysisComponent conduct={conduct} aar={aar} companyId={companyId}
                                                         portalId={conduct.portalId} editable={true}
                                                         scenarioId={scenarioId}
                                                         onUpdate={handleAARUpdate}
                                                         isPortal={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {selectedTab === 3 && conduct && aar && companyId && scenarioId &&conduct.portalId &&
                    <Grid item xs={12}>
                        <Grid container={true} spacing={2} >
                            <Grid item xs={12}>
                                <RecommendationAnalysisComponent conduct={conduct} aar={aar} companyId={companyId}
                                                         portalId={conduct.portalId} editable={true}
                                                         scenarioId={scenarioId}
                                                         onUpdate={handleAARUpdate}
                                                                 persistAnalysis={handleRecommendationAnalysisPersist}
                                                                 persistAnalysisValue={recommendationAnalysis}
                                                         isPortal={false}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
          </MainCard>
          </Grid>
                </MainCard>
          <Dialog open={openPublishDialog} fullWidth={true} onClose={closePublishDialog}>
              <DialogTitle>Publish AAR to Portal</DialogTitle>
              <DialogContent>
                  <FormControl fullWidth>
                      {companyId && <PortalListSelect companyId={companyId} selectLabel={"Select Publishing Portal"} label={"Select Portal"} onPortalSelect={handlePortalSelect} />}
                  </FormControl>
              </DialogContent>
              <DialogActions>
                  <Button onClick={closePublishDialog}>Cancel</Button>
                  <Button onClick={handlePublishAAR} color="primary">Publish</Button>
              </DialogActions>
          </Dialog>
          <Dialog open={openUnpublishDialog} fullWidth={true} onClose={() => setOpenUnpublishDialog(false)}>
              <DialogTitle>Unpublish AAR </DialogTitle>
              <DialogContent>
                  <Typography variant="body1">
                      You are unpublishing the AAR for this tabletop to the portal. The AAR will NOT BE visible and will become editable.
                  </Typography>

              </DialogContent>
              <DialogActions>
                  <Button variant="contained" onClick={handleUnpublishAAR} color="primary" >Unpublish</Button>
                  <Button variant="contained" onClick={() => setOpenUnpublishDialog(false)}>Cancel</Button>
              </DialogActions>
          </Dialog>
                <Dialog open={openRegenerateDialog} fullWidth={true} onClose={closeRegenerateDialog}>
                    <DialogTitle>Regenerate AAR</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            You are about to regenerate the AAR for this tabletop. This action will overwrite the current AAR.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleRegenerateAAR} color="primary">Regenerate</Button>
                        <Button variant="contained" onClick={closeRegenerateDialog}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openSendtoDialog} fullWidth={true} onClose={() => setOpenSendtoDialog(false)}>
                    <DialogTitle>Send AAR</DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            You are about to send the AAR for this tabletop to a recipient. This action will send the AAR to the recipient.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={() => setOpenSendtoDialog(false)} color="primary">Send</Button>
                        <Button variant="contained" onClick={() => setOpenSendtoDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
    </Grid>
      </Grid>
  );
};

export default AfterActionReport;