import React, { useEffect, useState } from 'react';
import {Box, Button, Chip, Divider, Grid, List, ListItem, ListItemText, Stack, Typography} from '@mui/material';
import { Scenario } from '../../interfaces/interfaces';
import QuickLoaderComponent from "../cards/QuickLoaderComponent";
import ScenarioWalkthroughDialog from "../dialog/ScenarioWalkthroughDialog";
import {PlusOutlined} from "@ant-design/icons";
import { differenceInDays } from 'date-fns';


interface Props {
    onRefresh?: () => void;
}

const MasterScenarioLibraryComponent: React.FC<Props> = ({ onRefresh }) => {
    const [scenarios, setScenarios] = useState<Scenario[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [bannerText, setBannerText] = useState<string>('Loading Master Scenario Library...');


// Utility function to check if the scenario is new
    function isNewScenario(createDate: string | null): boolean {
        if (!createDate) return false;
        const createDateObj = new Date(createDate);
        const currentDate = new Date();
        return differenceInDays(currentDate, createDateObj) <= 90;
    }
    useEffect(() => {
        const fetchScenarios = async () => {
            try {
                setBannerText('Loading Master Scenario Library...');
                setLoading(true);
                const token = localStorage.getItem('token');
                const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/scenariolibrary/getscenarios/'+token);
                if (!response.ok) {
                    throw new Error('Failed to fetch scenarios');
                }
                const data: Scenario[] = await response.json();
                setScenarios(data);
            } catch (err) {
               console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchScenarios();
    }, []);

    const [scenarioWalkthroughOpen, setScenarioWalkthroughOpen] = useState(false);
    const [selectedScenario, setSelectedScenario] = useState<Scenario | null>(null);
    function handleScenarioWalkthroughOpen(scenario: Scenario) {
        setSelectedScenario(scenario);
        setScenarioWalkthroughOpen(true);
    }
    function handleScenarioWalkthroughClose() {
        setScenarioWalkthroughOpen(false);
    }

    async function handleAddScenario(scenarioId: string) {
        try {
            setBannerText('Adding Scenario to Your Library...');
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await fetch(process.env.REACT_APP_REST_API_URL + '/api/scenario/scenariolibrary/pushmasterscenario/' + token+'/'+scenarioId);
            if (!response.ok) {
                throw new Error('Failed to push scenario');
            }
            if (onRefresh) {
                onRefresh();
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
        {loading &&
            <Grid container spacing={2}>
            <Grid item xs={12}>
                <QuickLoaderComponent loadBanner={bannerText} />
            </Grid>
            </Grid>
        }
            <List>
                {selectedScenario && <ScenarioWalkthroughDialog scenario={selectedScenario} scenarioOverviewOpen={scenarioWalkthroughOpen} handleScenarioWalkthroughClose={handleScenarioWalkthroughClose} />}
                {scenarios && scenarios.map((scenario) => (
                    <ListItem key={scenario.scenarioId}>
                        <Grid container={true} spacing={2} >
                            <Grid item xs={12}>
                                <ListItemText
                                    primary={
                                        <Box display="flex" alignItems="flex-start">
                                            {isNewScenario(scenario.userAudit?.createdDate) && (
                                                <Chip color="success" label="New" size="small" variant="filled" style={{ marginLeft: 8 }} />
                                            )}
                                            <Typography variant="body1">&nbsp; &nbsp;{scenario.name}</Typography>
                                        </Box>
                                    }
                                    secondary={scenario.description}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" justifyContent={"flex-start"} spacing={1}>

                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row" justifyContent={"flex-start"} spacing={1}>
                                    <Button variant={"outlined"} size={"small"} onClick={() => handleScenarioWalkthroughOpen(scenario)}>Walkthrough</Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack direction="row" justifyContent={"flex-end"} spacing={1}>
                                    <Button variant={"outlined"} startIcon={<PlusOutlined />} size={"small"} onClick={() => handleAddScenario(scenario.scenarioId)}>Add</Button>
                                </Stack>
                            </Grid>
                            <Grid item xs={11}>
                                <Divider/>
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default MasterScenarioLibraryComponent;